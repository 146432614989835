// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
require("turbolinks").start()

require("jquery")
require("@rails/activestorage").start()
require("channels")

require("trix")
//require("chartkick")
//require("chart.js")
require("jquery")
require("@nathanvda/cocoon")
require("chartkick")
require("chart.js")


require("@rails/actiontext")
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import AOS from 'aos';

import 'aos/dist/aos.css'; // You can also use <link> for styles


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import { createPopper } from '@popperjs/core';
import 'bootstrap';
import Trix from 'trix';




//import flatpickr from 'flatpickr';
//import { German } from "flatpickr/dist/l10n/de.js";
//import Chart from 'chart.js';



import ahoy from "ahoy.js";
import "chartkick/chart.js"



document.addEventListener('turbolinks:load', () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y4D3ZVY6ZK', {
    'cookie_flags': 'max-age=7200;secure;samesite=none'
  });
});
// ..
document.addEventListener("turbolinks:load", () =>{
AOS.init();

});

//import AOS from 'aos';

// ..
document.addEventListener("turbolinks:load", () =>{

  window.Chartkick = Chartkick
});

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
    .then(function(reg) {
      console.log('[Page] Service worker registered!');
    });
};


/*
window.Chartkick = Chartkick
Chartkick.addAdapter(Chart)
Chartkick.options = {
  library: {animation: {easing: 'easeOutQuart'}},
}
*/
document.addEventListener("turbolinks:load", () =>{
var Trix  = require("trix")
console.log("Config", Trix.config);

Trix.config.blockAttributes.heading1 = {
  tagName: 'h6'
}
Trix.config.blockAttributes.code = {
  
  tagName: 'code'
}


});



document.addEventListener("turbolinks:load", () =>{
  document.querySelectorAll('code').forEach((block) => {
    hljs.highlightBlock(block);
  });
});










$(document).ready(function() {
        // Transition effect for navbar 
        $(window).scroll(function() {
          // checks if window is scrolled more than 500px, adds/removes solid class
          if($(this).scrollTop() > 100) { 
              $('#sticky').hide();
              $('#new').show();
          } else {
              $('#new').hide();
              $('#sticky').show();
          }
        });


});


$(document).on('click', 'a[href^="#Scroll"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 800);
});
$(document).ready(function() {
$(".panel-heading").hover(
  function() {
    $('#services_collapse').collapse('show');
  }, function() {
    $('#services_collapse').collapse('hide');
  }
);

});
import "controllers"
