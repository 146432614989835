import { Controller } from "stimulus"
import Sortable from "sortablejs"

import Rails from "@rails/ujs"

export default class extends Controller {
	
  connect() {
  	

	//console.log(this.data.get("datenew"))
	//console.log(this.datedayTarget)
    
    this.sortable = 
    Sortable.create(
    	this.element, {
    	/*
    	group: {
		    name: 'calenderdate',
		   // put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
		   pull: true,
		   put:true
		   
		  },
    	*/
    	animation: 150,
    	ghostClass: 'bg-light',
    	onEnd: this.end.bind(this)
    	
    })
    

  }


  end(event){
	
  	
  	let id = event.item.dataset.id

  	//let postion = event.to.dataset.position
  	console.log("Position: ",event.newIndex + 1, "ID: ")
	console.log(event)


	let data = new FormData()
	//data.append("next_contact", event.to.id)
	data.append("position", event.newIndex + 1)
	

	Rails.ajax({
		url: this.data.get("url").replace(":id", id),
		type:"PATCH",
		data: data,
		success: function(data) {  },
		error: function(data) { alert("Fehler") }


	})
	}

}
